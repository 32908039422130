import {
  CellId,
  SharedFilterAutoLinks,
  SharedFilterManualLinks,
  StaticCellId,
} from "@hex/common";
import { partition } from "lodash";

import {
  CellContentsMP,
  CellMP,
} from "../../redux/slices/hexVersionMPSlice.js";

import { getSharedFilterableDataFrameNamesForCell } from "./sharedFilterUtils.js";
import { SharedFilterLink } from "./types.js";

export function getSharedFilterLinks({
  allCells,
  autoLinks,
  cellsInApp,
  getCellContents,
  manualLinks,
}: {
  autoLinks: SharedFilterAutoLinks;
  manualLinks: SharedFilterManualLinks;
  allCells: CellMP[];
  cellsInApp: CellMP[];
  getCellContents: (cellId: CellId) => CellContentsMP | undefined;
}): SharedFilterLink[] {
  const links: SharedFilterLink[] = [];
  const staticIdsInApp = new Set(cellsInApp.map((c) => c.staticId));

  const [excludeManualLinks, keepManualLinks] = partition(
    manualLinks,
    (ml) => ml.isExclude === true,
  );

  for (const link of keepManualLinks) {
    links.push({
      staticCellId: link.staticCellId,
      dataFrame: link.dataFrame,
      dataFrameColumn: link.dataFrameColumn,
      inApp: staticIdsInApp.has(link.staticCellId),
    });
  }

  const excludedLinkKeys = new Set(
    excludeManualLinks.map((ml) =>
      linkKey(ml.dataFrame, ml.dataFrameColumn, ml.staticCellId),
    ),
  );

  const [allCellsAutoLinks, appCellsAutoLinks] = partition(
    autoLinks,
    (al) => al.includeNonAppCells === true,
  );

  links.push(
    ...getLinks({
      autoLinks: allCellsAutoLinks,
      cells: allCells,
      excludedLinkKeys,
      getCellContents,
      staticIdsInApp,
    }),
    ...getLinks({
      autoLinks: appCellsAutoLinks,
      cells: cellsInApp,
      excludedLinkKeys,
      getCellContents,
      staticIdsInApp,
    }),
  );

  return links;
}

/**
 * Creates a mapping from dataFrame to the set of columns that are referenced in an auto linked shared filter.
 */
export function getDataframeColumnsForAutoLinks(
  autoLinks: SharedFilterAutoLinks,
): Record<string, Set<string>> {
  const dataFrameToColumns: Record<string, Set<string>> = {};

  for (const { dataFrame, dataFrameColumn } of autoLinks) {
    if (dataFrame != null && dataFrameColumn != null) {
      dataFrameToColumns[dataFrame] ??= new Set<string>();
      dataFrameToColumns[dataFrame].add(dataFrameColumn);
    }
  }

  return dataFrameToColumns;
}

function getLinks({
  autoLinks,
  cells,
  excludedLinkKeys,
  getCellContents,
  staticIdsInApp,
}: {
  autoLinks: SharedFilterAutoLinks;
  excludedLinkKeys: Set<string>;
  cells: CellMP[];
  getCellContents: (cellId: CellId) => CellContentsMP | undefined;
  staticIdsInApp: Set<StaticCellId>;
}): SharedFilterLink[] {
  const dataFrameToColumns = getDataframeColumnsForAutoLinks(autoLinks);

  const links: SharedFilterLink[] = [];
  for (const cell of cells) {
    const cellContents = getCellContents(cell.id);
    const usedDataFrames =
      cellContents != null
        ? getSharedFilterableDataFrameNamesForCell(cellContents).filter(
            (df) => df in dataFrameToColumns,
          )
        : [];

    for (const dataFrame of usedDataFrames) {
      dataFrameToColumns[dataFrame].forEach((dataFrameColumn) => {
        const key = linkKey(dataFrame, dataFrameColumn, cell.staticId);

        if (!excludedLinkKeys.has(key)) {
          links.push({
            staticCellId: cell.staticId,
            dataFrame,
            dataFrameColumn,
            inApp: staticIdsInApp.has(cell.staticId),
          });
        }
      });
    }
  }
  return links;
}

function linkKey(
  dataFrame: string,
  dataFrameColumn: string,
  staticId: StaticCellId,
): string {
  return `${dataFrame}+${dataFrameColumn}+${staticId}`;
}
